import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Input,
  Button,
  Switch,
  useColorModeValue,
} from "@chakra-ui/react";
import Cookies from 'js-cookie';

export default function UserReports() {
  const boxBg = useColorModeValue("white", "secondaryGray.300");
  const history = useHistory();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [airtimeAmount, setAirtimeAmount] = useState('');
    const [userData, setUserData] = useState({});
  const [dataPlan, setDataPlan] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [fundingData, setFundingData] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [transactionPin, setTransactionPin] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [bypassValidation, setBypassValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [dataPlans, setDataPlans] = useState([]);
  const authorizationToken = Cookies.get('authorization');
  const jwtToken = Cookies.get('authorization');

  const [serviceStatus, setServiceStatus] = useState({
    light: "0",
    social: "0",
    flyer: "0",
    airtime: "0",
    funding: "0",
    notifications: ""
  });

  useEffect(() => {
    const jwtToken = authorizationToken;
    if (jwtToken) {
setIsUserLoggedIn(true);
    } else {
setIsUserLoggedIn(false);
history.push('/auth/sign-in');
    }
  }, [history]);

  useEffect(() => {
    const fetchServiceStatus = async () => {
try {
  const response = await axios.get(`${BASE_URL}/status`);
  setServiceStatus(response.data.services);
} catch (error) {
  console.error('Error fetching service status:', error);
}
    };

    fetchServiceStatus();
  }, []);


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({}); // Initialize responseData


useEffect(() => {
siteInfo();
}, []); 

const siteInfo = async () => {
try {
const response = await fetch(`${BASE_URL}/site_info`, {
method: 'GET',
});

if (!response.ok) {
return;
}

const responseData = await response.json();
setResponseData(responseData); // Update the state with responseData

const newPageTitle = responseData.site_name && responseData.site_slogan
? `${responseData.site_name} || ${responseData.site_slogan}`
: responseData.site_name || responseData.ref_perc || 'Default Title';

setPageTitle(newPageTitle);

} catch (error) {
alert('Please Check your internet connection');
}
};

 useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);
const colortheme =responseData.colorTheme;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const fetchUserData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/user`, {
method: 'GET',
headers: {
  Authorization: jwtToken,
},
    });

    if (!response.ok) {
const errorResponse = await response.json();
return;
    }

    const data = await response.json();
    setUserData(data.userInformation);
    setFundingData(data.funding);
    setDataPlan(data.data_plan); 
    setNetworks(data.networks);
	

  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};

fetchUserData();





  const handleBuyNow = async () => {
	    const alphanum = Math.random().toString(36).substr(2, 13).toUpperCase();
    if (!selectedNetwork || !phoneNumber) {
return;
    }

    setIsLoading(true);
const mypin = userData.pin;

if (transactionPin !== mypin) {
setIsLoading(false);
Swal.fire({
  icon: 'error',
  text: 'Incorrect Transaction PIN. If you`ve forgotten your transaction PIN, please reach out to us for assistance.',
}).then(() => {});
return;
    }
    try {
if (!jwtToken) {
  throw new Error('Unauthorized');
}

const formData = {
  network: selectedNetwork,
  airtime_amount: airtimeAmount,
  phoneNumber: phoneNumber,
  transactionPin: transactionPin,
  bypassValidation: bypassValidation,
  order_id: alphanum,
};

const response = await axios.post(`${BASE_URL}/api/airtime`, formData, {
  headers: {
    Authorization: jwtToken,
  },
});

setIsLoading(false);

const successMessage = response.data.message;
Swal.fire({
  icon: 'success',
  text: successMessage,
});
 window.location.href = '#/admin/summary';
    } catch (error) {
console.error('Error making API request:', error.response ? error.response.data.error : 'An error occurred');
Swal.fire({
  icon: 'error',
  text: error.response ? error.response.data.error : 'An error occurred.',
});
setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkPhoneNumberNetwork = () => {
const networkPrefixes = {
  MTN: ['0703', '0706', '0803', '0806', '0810', '0813', '0814', '0816', '0903', '0906', '0913'],
  Glo: ['0705', '0805', '0807', '0811', '0815', '0905'],
  Airtel: ['0701', '0708', '0702', '0704', '0802', '0808', '0812', '0901', '0902', '0904', '0907', '0912'],
  '9mobile': ['0809', '0817', '0818', '0909', '0908'],
};

const numberPrefix = phoneNumber.substr(0, 4);
let network = '';
Object.entries(networkPrefixes).forEach(([networkName, prefixes]) => {
  if (prefixes.includes(numberPrefix)) {
    network = networkName;
  }
});

setNetworkName(network);
    };

    if (!bypassValidation) {
checkPhoneNumberNetwork();
    } else {
setNetworkName('');
    }
  }, [phoneNumber, bypassValidation]);

  return (
    <Box pt={{ base: "50px", md: "80px", xl: "80px" }}>
<Box
  bg={boxBg}
  p="20px"
  borderRadius="lg"
  shadow="base"
  width={{ base: "100%", md: "45%", xl: "45%" }}
  mx={{ base: "0", md: "auto" }}
  mb="20px"
>
  <Flex justify="space-between" align="center" mb="20px">
    <Heading as="h3" size="lg">
Buy Airtime
    </Heading>
  </Flex>
  {serviceStatus.airtime === "1" ? (
    <form>
<FormControl id="network" mb="4">
  <FormLabel>Network</FormLabel>
  <Select
    placeholder="Select network"
    value={selectedNetwork}
    onChange={(e) => setSelectedNetwork(e.target.value)}
  >
    <option value="MTN">MTN</option>
    <option value="GLO">Glo</option>
    <option value="ETISALAT">9mobile</option>
    <option value="AIRTEL">Airtel</option>
  </Select>
</FormControl>
<FormControl id="airtime_amount" mb="4">
  <FormLabel>Amount</FormLabel>
  <Input
    type="text"
    placeholder="Enter airtime amount"
    value={airtimeAmount}
    onChange={(e) => setAirtimeAmount(e.target.value)}
  />
</FormControl>
<FormControl id="phoneNumber" mb="4">
  <FormLabel>Phone Number</FormLabel>
  <Input
    type="number"
    autoComplete="off"
    placeholder="Enter phone number"
    value={phoneNumber}
    onChange={(e) => setPhoneNumber(e.target.value)}
  />
</FormControl>
<FormControl display="flex" alignItems="center">
  <FormLabel htmlFor="bypassValidation" mb="0">
    Bypass Number Validation
  </FormLabel>
  <Switch
    id="bypassValidation"
    ml="2"
    isChecked={bypassValidation}
    onChange={(e) => setBypassValidation(e.target.checked)}
  />
</FormControl>
{networkName && !bypassValidation && (
  <Box my="2" color="green">
    This is an <b>{networkName}</b> number.
  </Box>
)}
<FormControl id="transactionPin" mb="4">
  <FormLabel>Transaction Pin</FormLabel>
  <Input
    type="password"
    placeholder="****"
    value={transactionPin}
    onChange={(e) => setTransactionPin(e.target.value)}
  />
</FormControl>
<Button
  bg={colortheme}
  color="white"
  mt="4"
  onClick={handleBuyNow}
  isLoading={isLoading}
  loadingText="Placing Order"
>
  Confirm Order
</Button>
    </form>
  ) : (
    <p>Airtime is currently unavailable.</p>
  )}
</Box>

<LoadingOverlay
  active={isLoading}
  spinner
  text='Loading...'
  styles={{
    overlay: (base) => ({
...base,
background: 'rgba(0, 0, 0, 0.5)',
zIndex: 9999,
    }),
  }}
/>
    </Box>
  );
}
