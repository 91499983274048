import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';


export default function UserReports() {
const boxBg = useColorModeValue('white', 'secondaryGray.300');
const history = useHistory();

const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
const [histories, setHistories] = useState([]);
const jwtToken = Cookies.get('authorization');
const BASE_URL = process.env.REACT_APP_BASE_URL;
useEffect(() => {
if (jwtToken) {
setIsUserLoggedIn(true);
fetchUserData();
} else {
setIsUserLoggedIn(false);
history.push('/auth/sign-in');
}
}, [history, jwtToken]);

const fetchUserData = async () => {
try {
const response = await fetch(`${BASE_URL}/api/user`, {
method: 'GET',
headers: {
Authorization: jwtToken,
},
});

if (!response.ok) {
const errorResponse = await response.json();
return;
}

const data = await response.json();
setHistories(data.histories);
} catch (error) {
console.error('Error fetching user data:', error);
}
};





const columns = React.useMemo(
  () => [
{
  accessor: 'all',
  Cell: ({ row }) => (
<div>
  <div>
<Link to={`/admin/receipt/${row.original.id}`}>

  <br />
  {row.original.service} 👉 {row.original.recipient}<br />
  Previous Balance: ₦{row.original.o_bal} <br />Order Amount: ₦{row.original.amount}<br />New Balance: ₦{row.original.n_bal}
  <hr /> 
</Link>
  </div>
  <div><small>Status: <b>{row.original.status}</b> - <i>{row.original.date}</i></small><br /></div>
</div>
  ),
},
{
  accessor: 'id',
},
  ],
  []
);









const {
getTableProps,
getTableBodyProps,
headerGroups,
page,
prepareRow,
setGlobalFilter,
state: { pageIndex, pageSize, globalFilter },
previousPage,
nextPage,
canNextPage,
} = useTable(
{
columns,
data: histories,
initialState: { pageIndex: 0, pageSize: 30 },
},
useFilters,
useGlobalFilter,
useSortBy,
usePagination
);

return (
<Box pt={{ base: '50px', md: '80px', xl: '80px' }}>
<Box
bg={boxBg}
p="20px"
borderRadius="lg"
shadow="base"
width={{ base: '100%', md: '100%', xl: '100%' }}
mx={{ base: '0', md: 'auto' }}
mb="20px"
>
<input
type="text"
value={globalFilter || ''}
onChange={(e) => setGlobalFilter(e.target.value)}
placeholder="Search..."
/>
<table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
<thead>
{headerGroups.map((headerGroup) => (
<tr {...headerGroup.getHeaderGroupProps()}>
{headerGroup.headers.map((column) => (
<th
{...column.getHeaderProps(column.getSortByToggleProps())}
style={{
padding: '8px',
borderBottom: '2px solid #ccc',
background: '#f0f0f0',
fontWeight: 'bold',
textAlign: 'left',
}}
>
{column.render('Header')}
<span style={{ marginLeft: '5px' }}>
{column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''}
</span>
</th>
))}
</tr>
))}
</thead>
<tbody {...getTableBodyProps()}>
{page.map((row) => {
prepareRow(row);
return (
<tr {...row.getRowProps()}>
{row.cells.map((cell) => {
return (
<td
{...cell.getCellProps()}
style={{
padding: '8px',
borderBottom: '1px solid #ccc',
textAlign: 'left',
}}
>
{cell.render('Cell')}
</td>
);
})}
</tr>
);
})}
</tbody>
</table>
<div>
<button
onClick={() => previousPage()}
disabled={pageIndex === 0}
style={{
padding: '8px 12px',
marginRight: '5px',
cursor: pageIndex === 0 ? 'not-allowed' : 'pointer',
backgroundColor: pageIndex === 0 ? '#ccc' : '#007BFF',
color: 'white',
border: 'none',
borderRadius: '5px',
}}
>
Previous
</button>
<button
onClick={() => nextPage()}
disabled={!canNextPage}
style={{
padding: '8px 12px',
cursor: !canNextPage ? 'not-allowed' : 'pointer',
backgroundColor: !canNextPage ? '#ccc' : '#007BFF',
color: 'white',
border: 'none',
borderRadius: '5px',
}}
>
Next
</button>
</div>
</Box>
</Box>
);
}
