import {
  Flex,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
  const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function Conversion(props) {
  const { ...rest } = props;

  const [invitees, setinvitees] = useState([]);
  const [filteredinvitees, setFilteredinvitees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const jwtToken = Cookies.get('authorization');

  // Fetch invitees on component mount
  useEffect(() => {
    fetchinvitees();
  }, []);

  const fetchinvitees = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/invitees`, {
headers: {
  Authorization: jwtToken,
},
      });
      const data = await response.json();
      setinvitees(data.invitees);
      setFilteredinvitees(data.invitees);
    } catch (error) {
      console.error("Error fetching invitees:", error);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = invitees.filter(
      (contact) =>
(contact.name && contact.name.toLowerCase().includes(query)) ||
(contact.phoneNumber && contact.phoneNumber.includes(query))
    );
    setFilteredinvitees(filtered);
  };

  const handleCopyPhoneNumber = (phoneNumber, name) => {
    navigator.clipboard.writeText(phoneNumber);

    // Use SweetAlert to display the alert
    Swal.fire({
      text: `${name} phone number copied.`,
      showConfirmButton: false,
      timer: 1500 // Display for 2 seconds
    });
  };

  return (
    <Card p='20px' align='center' height='450px' direction='column' w='100%' {...rest} style={{ maxHeight: '300px', overflowY: 'scroll' }}>
      <Flex
px={{ base: "1px", "1xl": "20px" }}
justifyContent='space-between'
alignItems='center'
w='100%'
mb='5px'>
<Text fontSize='md' fontWeight='300' mt='8px'>
  My Invitees
</Text>
</Flex>
<Table variant='simple'>
<Tbody>
  {filteredinvitees.map((contact) => (
    <Tr key={contact.id}>
      <Td><b>{contact.name}</b>
<i size='sm' colorScheme='brand'> {contact.activated}</i>
      </Td>
    </Tr>
  ))}
</Tbody>
      </Table>
    </Card>
  );
}
