import React, { useEffect, useState } from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
const BASE_URL = process.env.REACT_APP_BASE_URL;


function SignIn() {
	

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// add this above:
// const [pageTitle, setPageTitle] = useState("User Reports"); // Default title
const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({}); // Initialize responseData


useEffect(() => {
  siteInfo();
}, []); 

const siteInfo = async () => {
  try {
    const response = await fetch(`${BASE_URL}/site_info`, {
      method: 'GET',
    });

    if (!response.ok) {
      return;
    }

    const responseData = await response.json();
    setResponseData(responseData); // Update the state with responseData

    const newPageTitle = responseData.site_name && responseData.site_slogan
      ? `${responseData.site_name} || ${responseData.site_slogan}`
      : responseData.site_name || responseData.ref_perc || 'Default Title';

    setPageTitle(newPageTitle);

  } catch (error) {
    alert('Network Error');
  }
};

 useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	
	
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Text color={textColor} fontSize="36px" mb="10px">
          Contact Us
        </Text>
        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          For any inquiries or assistance, please contact us using the details below:
        </Text>
        <Flex flexDirection="column" justifyContent="start" alignItems="start">

          <Text color={textColor} fontWeight="500" fontSize="19px">
            Contact Phone: {responseData.contact_number}
          </Text>
   
          <Text color={textColor} fontWeight="500" fontSize="19px">
            Office Address: {responseData.address}
          </Text>
        </Flex>
        
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
