import React, { useState, useEffect } from "react";
import { Box, Grid } from "@chakra-ui/react";
import useAuthentication from "../../../utils/auth";
import Cookies from "js-cookie";
import General from "views/admin/profile/components/General";
import { SimpleGrid, Text, useColorModeValue, FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";

export default function Overview() {
  const jwtToken = Cookies.get("authorization");
  const isUserLoggedIn = useAuthentication();

  const [userData, setUserData] = useState(null);
  const [fundingData, setFundingData] = useState(null);
  const [dataPlan, setDataPlan] = useState(null);
  const [networks, setNetworks] = useState(null);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [webhookUrl, setWebhookUrl] = useState("");
const BASE_URL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/user`, {
          method: "GET",
          headers: {
            Authorization: jwtToken,
          },
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          return;
        }

        const data = await response.json();
        setUserData(data.userInformation);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [jwtToken]);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseColor, setResponseColor] = useState("red");

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/api/update`, {
        method: "POST",
        headers: {
          Authorization: jwtToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPassword,
          newPassword,
          confirmPassword,
          oldPin,
          newPin,
          confirmPin,
          webhookUrl,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        if (responseData.message) {
          setResponseColor("green");
          setResponseMessage(responseData.message);
        } else {
          setResponseColor("red");
          setResponseMessage(responseData.error);
        }
      } else {
        setResponseColor("red");
        setResponseMessage(responseData.error);
      }
    } catch (error) {
      console.error("Error updating data:", error);
      setResponseColor("red");
      setResponseMessage("An error occurred while updating.");
    } finally {
      setLoading(false);
    }
  };
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({}); // Initialize responseData


useEffect(() => {
siteInfo();
}, []); 

const siteInfo = async () => {
try {
const response = await fetch(`${BASE_URL}/site_info`, {
method: 'GET',
});

if (!response.ok) {
return;
}

const responseData = await response.json();
setResponseData(responseData); // Update the state with responseData

const newPageTitle = responseData.site_name && responseData.site_slogan
? `${responseData.site_name} || ${responseData.site_slogan}`
: responseData.site_name || responseData.ref_perc || 'Default Title';

setPageTitle(newPageTitle);

} catch (error) {
alert('Please Check your internet connection');
}
};

 useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);
const colortheme =responseData.colorTheme;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <Box pt={{ base: "50px", md: "80px", xl: "80px" }}>
      <Grid
        mb="20px"
        templateColumns={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "1xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "1xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Card
          gridArea={{ base: "1", lg: "1" }}
          minH="365px"
          pe="20px"
          mb={{ base: "0px", "2xl": "20px" }}
        >
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Update
          </Text>
          <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
            Update account, Note: If you are an API user, you need to update
            your password in your script as soon as you update your password
            here.
          </Text>
          <form onSubmit={handleFormSubmit}>
            <FormControl id="oldPassword">
              <FormLabel>Old Password</FormLabel>
              <Input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </FormControl>
            <FormControl id="newPassword">
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FormControl>
            <FormControl id="confirmPassword">
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <br /><br />
            <hr />
            To update your transaction pin, make use of this form
            <FormControl id="oldPin">
              <FormLabel>Old Transaction Pin</FormLabel>
              <Input
                type="password"
                value={oldPin}
                onChange={(e) => setOldPin(e.target.value)}
              />
            </FormControl>
            <FormControl id="newPin">
              <FormLabel>New Transaction Pin</FormLabel>
              <Input
                type="password"
                value={newPin}
                onChange={(e) => setNewPin(e.target.value)}
              />
            </FormControl>
            <FormControl id="confirmPin">
              <FormLabel>Confirm New Transaction Pin</FormLabel>
              <Input
                type="password"
                value={confirmPin}
                onChange={(e) => setConfirmPin(e.target.value)}
              />
            </FormControl>
            <br /><br />
  
  <FormControl id="webhookUrl">
  <br />
  <FormLabel>Developer API key (DO NOT SHARE WITH ANYONE)</FormLabel>

<Input type="text" value={jwtToken.replace(/Basic/g, '')} readOnly />

</FormControl>
  
  
  
            <Button mt="20px"   bg={colortheme}
  color="white" type="submit" isLoading={loading}>
              Update Profile
            </Button>
            <Text mt="10px" color={responseColor}>
              {responseMessage}
            </Text>
          </form>
        </Card>
      </Grid>
    </Box>
  );
}
