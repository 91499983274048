import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Input,
  Button,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import Modal from 'react-modal';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useReactToPrint } from 'react-to-print';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  section: {
    flexGrow: 1,
  },
});

const authorizationToken = Cookies.get('authorization');

export default function UserReports() {
  const boxBg = useColorModeValue("white", "secondaryGray.300");
  const history = useHistory();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [phone, setphone] = useState('');
  const [airtimeAmount, setAirtimeAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [bypassValidation, setBypassValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataPlans, setDataPlans] = useState([]);
  const [responseText, setResponseText] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [previousData, setPreviousData] = useState([]);
  const [lightStatus, setLightStatus] = useState('');
  const jwtToken = Cookies.get('authorization');
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (jwtToken) {
      setIsUserLoggedIn(true);
      fetchLightStatus();
    } else {
      setIsUserLoggedIn(false);
      history.push('/auth/sign-in');
    }
  }, [history]);

  const fetchLightStatus = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/status`);
      const services = response.data.services;
      setLightStatus(services.light);
    } catch (error) {
      console.error('Error fetching light status:', error);
    }
  };

  const pdfRef = useRef();

  const handlePrintPDF = useReactToPrint({
    content: () => pdfRef.current,
  });

  const handleDownloadPDF = () => {
    const blob = new Blob([responseText], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'response.pdf';
    a.click();
  };
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({}); // Initialize responseData


useEffect(() => {
siteInfo();
}, []); 

const siteInfo = async () => {
try {
const response = await fetch(`${BASE_URL}/site_info`, {
method: 'GET',
});

if (!response.ok) {
return;
}

const responseData = await response.json();
setResponseData(responseData); // Update the state with responseData

const newPageTitle = responseData.site_name && responseData.site_slogan
? `${responseData.site_name} || ${responseData.site_slogan}`
: responseData.site_name || responseData.ref_perc || 'Default Title';

setPageTitle(newPageTitle);

} catch (error) {
alert('Please Check your internet connection');
}
};

 useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);
const colortheme =responseData.colorTheme;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleBuyNow = async () => {
    if (lightStatus === '0') {
      Swal.fire({
        icon: 'warning',
        text: 'Light subscription is currently unavailable.',
      });
      return;
    }

    if (!selectedNetwork || !phoneNumber) {
      return;
    }

    setIsLoading(true);

    try {

      if (!jwtToken) {
        throw new Error('Unauthorized');
      }

      const formData = {
        network: selectedNetwork,
        airtime_amount: airtimeAmount,
        phoneNumber: phoneNumber,
        phone: phone,
      };

      const response = await axios.post(`${BASE_URL}/api/power`, formData, {
        headers: {
          Authorization: jwtToken,
        },
      });

      setIsLoading(false);
      setResponseText(JSON.stringify(response.data, null, 2));
      setResponseMessage(response.data.message);
 window.location.href = '#/admin/summary';
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });

      const {
        disco_name,
        'request-id': request_id,
        amount,
        meter_number,
        meter_type,
        token,
      } = response.data;

      const tableDataObj = {
        disco_name,
        request_id,
        amount,
        meter_number,
        meter_type,
        token,
      };

      setPreviousData((prevData) => [...prevData, tableDataObj]);
    } catch (error) {
      console.error('Error making API request:', error.response ? error.response.data.error : 'An error occurred while making the API request.');
      Swal.fire({
        icon: 'error',
        text: error.response ? error.response.data.error : 'An error occurred while making the API request.',
      });
      setIsLoading(false);
    }
  };

  const handlePhoneNumberChange = async (e) => {
    setPhoneNumber(e.target.value);

    if (e.target.value.length === 11) {
      setIsLoading(true);

      try {
        const response = await axios.get(`https://vtu9ja.ng/api/bill/bill-validation?meter_number=${e.target.value}&disco=${selectedNetwork}&meter_type=prepaid`);

        if (response.data.status === 'success') {
          setNetworkName(response.data.name);
        } else {
          setNetworkName('Error: ' + response.data.message);
        }
      } catch (error) {
        console.error('Error validating meter number:', error);
        setNetworkName('Error: Unable to validate meter number.');
      }

      setIsLoading(false);
    } else {
      setNetworkName('');
    }
  };

  const PDFDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>{responseText}</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <Box pt={{ base: "50px", md: "80px", xl: "80px" }}>
      <Box
        bg={boxBg}
        p="20px"
        borderRadius="lg"
        shadow="base"
        width={{ base: "100%", md: "45%", xl: "45%" }}
        mx={{ base: "0", md: "auto" }}
        mb="20px"
      >
        <Flex justify="space-between" align="center" mb="20px">
          <Heading as="h3" size="lg">
            Electricity
          </Heading>
        </Flex>
        {lightStatus === '0' ? (
          <div>Light subscription is currently unavailable.</div>
        ) : (
          <form>
            <FormControl id="network" mb="4">
              <FormLabel>Disco</FormLabel>
              <Select
                placeholder="Select Disco"
                value={selectedNetwork}
                onChange={(e) => setSelectedNetwork(e.target.value)}
              >
                <option value="1">Ikeja Electricity</option>
                <option value="2">Eko Electricity</option>
                <option value="3">Kano Electricity</option>
                <option value="4">Port Harcourt Electricity</option>
                <option value="5">Joss Electricity</option>
                <option value="6">Ibadan Electricity</option>
                <option value="7">Kaduna Electric</option>
                <option value="8">Abuja Electricity</option>
                <option value="9">Enugu Electricity</option>
                <option value="10">Benin Electricity</option>
              </Select>
            </FormControl>

            <FormControl id="phoneNumber" mb="4">
              <FormLabel>Meter Number</FormLabel>
              <Input
                type="text"
                placeholder="Enter meter number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              {networkName ? (
                <Box my="2" color={networkName.startsWith('Error:') ? "red" : "green"}>
                  {networkName}
                </Box>
              ) : null}
            </FormControl>

            <FormControl id="phone" mb="4">
              <FormLabel>Recipeint Phone</FormLabel>
              <Input
                type="text"
                placeholder="0900000"
                value={phone}
                onChange={(e) => setphone(e.target.value)}
              />
            </FormControl>

            <FormControl id="airtime_amount" mb="4">
              <FormLabel>Bill Amount + ₦100 Charge</FormLabel>
              <Input
                type="text"
                placeholder="Enter amount"
                value={airtimeAmount}
                onChange={(e) => setAirtimeAmount(e.target.value)}
              />
            </FormControl>

            <Button   bg={colortheme}
  color="white" mt="4" onClick={handleBuyNow} isLoading={isLoading || networkName === ''} loadingText="Awaiting Meter Validation...">
              {networkName === '' ? "Confirm Order" : "Meter Validated"}
            </Button>
          </form>
        )}
      </Box>

      <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading...'
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
          }),
        }}
      />

      <Modal
        isOpen={responseText !== ''}
        onRequestClose={() => setResponseText('')}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1000,
          },
          content: {
            border: 'none',
            background: 'transparent',
            width: '60%',
            maxWidth: '80%',
            margin: 'auto',
          },
        }}
      >
        <Box bg="white" p="20px" borderRadius="lg" shadow="base">
          <Heading as="h3" size="lg" mb="4">
            Electricity Payment
          </Heading>
          <Box
            as="pre"
            overflow="auto"
            maxHeight="500px"
            bg="gray.100"
            p="4"
            borderRadius="md"
          >
            Prepaid token has been generated successfully.<br />
            Please check the summary page to access the generated token.
          </Box>
          <Flex mt="4" justifyContent="space-between">
            <Link to="/summary">
              <Button colorScheme="green">
                Go to Summary
              </Button>
            </Link>
            <Button colorScheme="red" onClick={() => setResponseText('')}>
              Close
            </Button>
          </Flex>
        </Box>
      </Modal>

      <PDFViewer style={{ display: 'none' }} ref={pdfRef}>
        <PDFDocument />
      </PDFViewer>
    </Box>
  );
}
