import React, { useState, useEffect } from 'react';
import useAuthentication from '../../../utils/auth';
import {
Avatar,
Box,
Flex,
FormLabel,
Icon,
Text,
SimpleGrid,
useColorModeValue,
Table,
Thead,
Tbody,
Tr,
Th,
Td,
 Button,
  FormControl,
  Select,
} from "@chakra-ui/react";
import Mtn from "assets/img/dashboards/mtn.png";
import Airtel from "assets/img/dashboards/airtel.png";
import Etisalat from "assets/img/dashboards/etisalat.png";
import GLo from "assets/img/dashboards/glo.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";

import {
MdBalance,
MdBarChart,
MdVerifiedUser,
MdContentCopy,
} from "react-icons/md";
import Tasks from "views/admin/default/components/Tasks";
import Tasks2 from "views/admin/default/components/Tasks2";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function UserReports() {

const [selectedUpgrade, setSelectedUpgrade] = useState(""); 

 const [marqueeData, setMarqueeData] = useState([]);



const handleUpgradeSubmit = async () => {
if (selectedUpgrade) {
try {
const response = await fetch(`${BASE_URL}/api/upgrade`, {
method: 'POST',
headers: {
Authorization: jwtToken,
'Content-Type': 'application/json',
},
body: JSON.stringify({ upgradeOption: selectedUpgrade }),
});

if (!response.ok) {
const errorResponse = await response.json();
Swal.fire({
icon: 'error',
text: errorResponse.error || 'An error occurred while upgrading your account. Please try again later.',
});

return;
}

const successResponse = await response.json();

// Show success message from the backend response using Swal.fire
Swal.fire({
icon: 'success',
title: 'Upgrade Successful',
text: successResponse.message || 'Your account has been successfully upgraded!',
});

// Optionally, you can refresh the page
window.location.reload();
} catch (error) {
}
}
};


	
	
const handleGenerateFundingAccount = async () => {
try {
const response = await fetch(`${BASE_URL}/api/new_autofundin_account`, {
method: 'POST',
headers: {
Authorization: jwtToken,
},
});

if (!response.ok) {
const errorResponse = await response.json();
console.error('Error generating funding account:', errorResponse);
return;
} 

// Refresh the page after successful generation
window.location.reload();
} catch (error) {
console.error('Error generating funding account:', error);
}
};

const brandColor = useColorModeValue("brand.500", "white");
const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

const [fundingData, setFundingData] = useState([]);
const [userData, setUserData] = useState({});
const [dataPlan, setDataPlan] = useState([]);
const [networks, setNetworks] = useState([]);
const [levelPlan, setLevelPlan] = useState([]);
const [autoFundingStatus, setAutoFundingStatus] = useState('');

const isUserLoggedIn = useAuthentication();
const jwtToken = Cookies.get('authorization');


useEffect(() => {
if (isUserLoggedIn) {
fetchUserData();
fetchAutoFundingStatus();
}
}, [isUserLoggedIn]);

const fetchUserData = async () => {
try {
const response = await fetch(`${BASE_URL}/api/user`, {
method: 'GET',
headers: {
Authorization: jwtToken,
},
});

if (!response.ok) {
const errorResponse = await response.json();
return;
}

const data = await response.json();
setUserData(data.userInformation);
setFundingData(data.funding);
setDataPlan(data.data_plan);
setNetworks(data.networks);


} catch (error) {
console.error('Error fetching user data:', error);
}
};


 const fetchAutoFundingStatus = async () => {
try {
const response = await fetch(`${BASE_URL}/status`);

if (!response.ok) {
const errorResponse = await response.json();
console.error('Error fetching auto funding status:', errorResponse);
return;
}

const statusData = await response.json();
setAutoFundingStatus(statusData.services.funding === "0" ? statusData.services.bank_user : '');

if (statusData.services.notifications !== "") {

Swal.fire({
text: statusData.services.notifications,
icon: 'info',
showCancelButton: true,
showCloseButton: true,
confirmButtonText: 'Close',
cancelButtonText: 'WhatsApp Group',
cancelButtonAriaLabel: 'WhatsApp Group',
focusCancel: true,
showDenyButton: true,
denyButtonText: 'Customer Care',
denyAriaLabel: 'Customer Care',
}).then((result) => {
if (result.isConfirmed) {

} else if (result.isDenied) {
window.open(`https://wa.me/${responseData.contact_number}`, '_blank');
} else if (result.dismiss === Swal.DismissReason.cancel) {
window.open(`${responseData.whats_group}`, '_blank');
}
});

}
} catch (error) {
console.error('Error fetching auto funding status:', error);
}
};


//alert (userData.notification_count);
const history = useHistory();


if (userData.notification_count > 0) {
Swal.fire({
text: `You have ${userData.notification_count} unread notifications.`,
icon: 'info',
confirmButtonText: 'OK',
}).then((result) => {
if (result.isConfirmed) {
 history.push("notifications");
}
});
}



 useEffect(() => {
// Fetch data from the provided URL
const fetchData = async () => {
try {
const response = await fetch(`${BASE_URL}/pricing`);
const data = await response.json();
setMarqueeData(data.data_plan);
} catch (error) {
console.error("Error fetching data:", error);
}
};

fetchData(); // Call the fetchData function when the component mounts
}, []);






const getPriceFor1GB = (networkCode) => {
const plan = dataPlan.find(
(plan) =>
plan.network === Number(networkCode) && plan.data_size === "1GB"
);
return plan ? plan.amount : 0;
};

const getNetworkAvatar = (networkName) => {
switch (networkName) {
case "MTN":
return Mtn;
case "Glo":
return GLo;
case "9mobil":
return Etisalat;
case "Airtel":
return Airtel;
default:
return null;
}
};

const mymodal = (text) => {
Swal.fire({
text: `You currently have ${userData.bonus} Naira. You can move your bonus to your wallet at any time`,
showCancelButton: false,
showCloseButton: true,
confirmButtonText: 'OK',
cancelButtonAriaLabel: 'Move to Wallet',
focusCancel: false,
showDenyButton: true,
denyButtonText: 'Move to Wallet',
}).then(async (result) => {
if (result.isConfirmed) {
// Do something if the user clicks "OK"
} else if (result.isDenied) {
// Send POST request to move bonus to wallet
try {
const response = await fetch(`${BASE_URL}/api/movebonus`, {
method: 'POST',
headers: {
Authorization: jwtToken,
},
});

if (response.ok) {
// Success case
Swal.fire({
icon: 'success',
title: 'Wallet credited successfully!',
showConfirmButton: false,
timer: 1500,
});

window.location.reload();
} else {
// Error case
const errorResponse = await response.json();
Swal.fire({
icon: 'error',
text: errorResponse.error,
});
}
} catch (error) {
// Network or unexpected error
console.error('Error moving bonus:', error);

Swal.fire({
icon: 'error',
title: 'Error',
text: 'An unexpected error occurred. Please try again.',
});
}
}
});
};




//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({}); // Initialize responseData


useEffect(() => {
siteInfo();
}, []); 

const siteInfo = async () => {
try {
const response = await fetch(`${BASE_URL}/site_info`, {
method: 'GET',
});

if (!response.ok) {
return;
}

const responseData = await response.json();
setResponseData(responseData); // Update the state with responseData

const newPageTitle = responseData.site_name && responseData.site_slogan
? `${responseData.site_name} || ${responseData.site_slogan}`
: responseData.site_name || responseData.ref_perc || 'Default Title';

setPageTitle(newPageTitle);

} catch (error) {
alert('Please Check your internet connection');
}
};

 useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);
const colortheme =responseData.colorTheme;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const handleCopy = (text) => {
navigator.clipboard.writeText(text);
Swal.fire({
text: 'Content copied to clipboard',
timer: 1500, // Auto-close after 2 seconds
timerProgressBar: true,
showConfirmButton: false, // Hide the OK button
});
};




return (
<Box pt={{ base: "50px", md: "80px", xl: "80px" }}>


<SimpleGrid columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }} gap='20px' mb='20px'>
<MiniStatistics
startContent={
<IconBox
w='56px'
h='56px'
bg={boxBg}
icon={
<Icon w='52px' h='52px' as={MdBalance} color={colortheme} />
}
/>
}
name='Wallet Balance'
value={`₦${userData.balance}`}
/>












<a onClick={() => mymodal()}>
<MiniStatistics startContent={
<IconBox
w='56px'
h='56px'
bg={boxBg}
icon={
<Icon w='52px' h='52px' as={MdBarChart} color={colortheme} />
}
/>
}
name='Referral Bonus'
value={`₦${userData.bonus}`}
/></a>









<MiniStatistics
startContent={
<IconBox
w='56px'
h='56px'
bg={boxBg}
icon={
<Icon w='52px' h='52px' as={MdVerifiedUser} color={colortheme} />
}
/>
}
name='User Level'
value={userData.level}
/>
</SimpleGrid>



{autoFundingStatus ? (
<Box p='20px' align='center' direction='column' w='100%' bg="white" borderRadius="10px">
{autoFundingStatus}
</Box>
) : (
<>


<SimpleGrid columns={{ base: 1 }} p='20px' align='center' direction='column' w='100%' bg="white" borderRadius="10px">Invite friends and receive a lifetime reward of {responseData.ref_percentage}% on all wallet top-ups made by those you invited to this platform!
<br /><br />
<b>
<span className="copyable">
{responseData.website}/inv/{userData.ref_code}

<button className="copy-button" onClick={() => handleCopy(`${responseData.website}/inv/${userData.ref_code}`)}> <MdContentCopy size={26} style={{ marginLeft: '15px' }} />
</button>
</span></b>
</SimpleGrid><br />




{responseData.auto_funding === 0 ? (
<SimpleGrid columns={{ base: 1 }} p='20px' align='center' direction='column' w='100%' bg="white" borderRadius="10px">
<small>CURRENTLY, AUTOMATED FUNDING SERVICES ARE UNAVAILABLE. PLEASE TRANSFER FUNDS TO THE SPECIFIED ACCOUNT AND SHARE THE PAYMENT RECEIPT ALONG WITH YOUR EMAIL ADDRESS VIA WHATSAPP.</small>
<hr />
<b>
Bank: {responseData.manul_bak}<br />
Account Name: {responseData.manual_a_name}<br />
Account Number: {responseData.manual_acn} <button className="copy-button" onClick={() => handleCopy(responseData.manual_acn)}>
<MdContentCopy size={26} style={{ marginLeft: '15px' }} />
</button>
</b>
</SimpleGrid>
      ) : (
<SimpleGrid columns={{ base: 1 }} p='20px' align='center' direction='column' w='100%' bg="white" borderRadius="10px">
TRANSFER FUND INTO THIS ACCOUNT NUMBER & YOUR WALLET WILL BE CREDITED IMMEDIATELY.
<hr /><br />
{fundingData.map((bankAccount, index) => (
<p key={index}>
{bankAccount.bank}, {bankAccount.account_name}, <span className="copyable">{bankAccount.account_number}</span>
<button className="copy-button" onClick={() => handleCopy(bankAccount.account_number)}>
<MdContentCopy size={26} style={{ marginLeft: '15px' }} />
</button>
</p>
))}
</SimpleGrid>
      )}


<br />



<SimpleGrid columns={{ base: 2 }} p='20px' align='center' direction='column' w='100%' bg="white" color={colortheme} borderRadius="15px">
<a href={`tel:${responseData.contact_number}`}>Call</a>
<a href={`${responseData.whats_group}`}>Support Group</a>

 </SimpleGrid>


</>
)}



<br />

<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>


<Tasks />

<Tasks2 />
</SimpleGrid>

</SimpleGrid>

<br />


<SimpleGrid columns={{ base: 1 }} p="20px" align="center" direction="column" w="100%" bg="white" borderRadius="15px">
<Text fontSize="23px" mb="1px">
<Table variant="simple" size="sm" colorScheme="teal">
<Thead>
<Tr>
<Th>Network</Th>
<Th>Amount per Level</Th>
</Tr>
</Thead>
<Tbody>
{marqueeData.map((plan) => (

<Tr key={plan.id} _hover={{ background: "gray.100" }}>
<Td>{`${plan.plan_type.replace(/_/g, ' ')} ${plan.size}${plan.size_type}`}</Td>
<Td>

Enduser: ₦{plan.end_userAmount}<br />
Agent: ₦{plan.agent_Amount}<br />
Reseler: ₦{plan.api_user_amount}

</Td>
</Tr>



))}
</Tbody>
</Table>
<br />
</Text>
  <form style={{ display: 'block', textAlign: 'center', marginBottom: '20px' }}>
        <FormControl>
          <FormLabel fontSize="md" fontWeight="bold">
            Select Upgrade Plan
          </FormLabel>
          <Select
            value={selectedUpgrade}
            onChange={(e) => setSelectedUpgrade(e.target.value)}
            placeholder="Click to Select Upgrade Plan"
            size="lg"
          >
            <option value="agent_upgrade">Upgrade to Agent - ₦1,500</option>
            <option value="reseller_upgrade">Upgrade to Reseller/API - ₦3,000</option>
          </Select>
        </FormControl>
        <Button
          type="button"
          onClick={handleUpgradeSubmit}
          colorScheme="green"
          mt="4"
          size="lg"
          borderRadius="5px"
        >
          Level Up
        </Button>
      </form><hr />
</SimpleGrid>

</Box>
);
}

