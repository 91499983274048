import React, { useState, useEffect } from 'react';
import { Flex, useColorModeValue } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export function SidebarBrand() {

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// add this above:
// const [pageTitle, setPageTitle] = useState("User Reports"); // Default title
const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({}); // Initialize responseData


useEffect(() => {
  siteInfo();
}, []); 

const siteInfo = async () => {
  try {
    const response = await fetch(`${BASE_URL}/site_info`, {
      method: 'GET',
    });

    if (!response.ok) {
      return;
    }

    const responseData = await response.json();
    setResponseData(responseData); // Update the state with responseData

    const newPageTitle = responseData.site_name && responseData.site_slogan
      ? `${responseData.site_name} || ${responseData.site_slogan}`
      : responseData.site_name || responseData.ref_perc || 'Default Title';

    setPageTitle(newPageTitle);

  } catch (error) {
    alert('Network Error');
  }
};

 useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      {/* site_logo */}
<img src={responseData.site_logo} alt="Logo" style={{ width: "100%", height: "50px" }} />
      <HSeparator mb='10px' />
    </Flex>
  );
}

export default SidebarBrand;
