import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
import Swal from 'sweetalert2';


function SignUp() {
	
	
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// add this above:
// const [pageTitle, setPageTitle] = useState("User Reports"); // Default title
const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({}); // Initialize responseData


useEffect(() => {
  siteInfo();
}, []); 

const siteInfo = async () => {
  try {
    const response = await fetch(`${BASE_URL}/site_info`, {
      method: 'GET',
    });

    if (!response.ok) {
      return;
    }

    const responseData = await response.json();
    setResponseData(responseData); // Update the state with responseData

    const newPageTitle = responseData.site_name && responseData.site_slogan
      ? `${responseData.site_name} || ${responseData.site_slogan}`
      : responseData.site_name || responseData.ref_perc || 'Default Title';

    setPageTitle(newPageTitle);

  } catch (error) {
    alert('Network Error');
  }
};

 useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);
const colortheme =responseData.colorTheme;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	
	
  const location = useLocation();
  const siteEmail = process.env.REACT_APP_CONFIG_site_email;
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [inviteCode, setInviteCode] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const inviteParam = params.get('invite');

    if (inviteParam) {
      setInviteCode(inviteParam);
    }
  }, [location.search]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fullName = event.target.fullName.value;
    const phoneNumber = event.target.phoneNumber.value;
    const email = event.target.email.value;
    const transactionPin = event.target.transactionPin.value;
    const password = event.target.password.value;
    const agreedToTerms = event.target['agreed-to-terms'].checked;

    if (!fullName || !phoneNumber || !email || !transactionPin || !password || !agreedToTerms) {
      setError("Please fill in all the required fields and agree to the Terms of Service and Privacy Policy.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}/api/register`, {
        fullName,
        phoneNumber,
        email,
        transactionPin,
        password,
        agreedToTerms,
        refCode: inviteCode,
      });

      setResponseMessage(response.data.message);
      setLoading(false);
	  
	  
Swal.fire({
text: response.data.message,
});
	  
	  
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
		Swal.fire({
text: error.response.data.message,
});
      } else {
        setError("An error occurred during registration. Please try again.");
      }
      setLoading(false);
    }
  };

  const [loading, setLoading] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState("");
  const [error, setError] = React.useState("");

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "auto" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "10px", md: "10px" }}
        px={{ base: "10px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign Up
          </Heading>
          <Text
            mb="16px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="200"
            fontSize="md"
          >
            Get started absolutely free.
          </Text>
        </Box>
        <Flex
          zIndex=""
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit}>
           <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Full name<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Full name"
                mb="24px"
                fontWeight="500"
                size="lg"
                name="fullName"
              />

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Phone Number<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="tel"
                placeholder="09014532386"
                mb="24px"
                fontWeight="500"
                size="lg"
                name="phoneNumber"
              />

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="user@email.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                name="email"
              />

              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Transaction Pin<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="4 characters"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  name="transactionPin"
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
    <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Referral Code (optional)
            </FormLabel>
            <Input
             
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="OgaPositive"
              mb="24px"
              fontWeight="500"
              size="lg"
              name="ref_code"
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value)}
            />

              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  name="password"
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>

              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    id="agreed-to-terms"
                    colorScheme="brandScheme"
                    me="10px"
                    isRequired={true} // Make the checkbox compulsory
                  />
                  <FormLabel
                    htmlFor="agreed-to-terms"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    By registering, I agree to the Terms of Service and Privacy Policy.
                  </FormLabel>
                </FormControl>
              </Flex>
              {error && <Text color="red.500">{error}</Text>}
              {responseMessage && <Text color="green.500">{responseMessage}</Text>}
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
				bg={colortheme}
                mb="24px"
                isLoading={loading}
              >
                Sign Up
              </Button>
            </FormControl>

          </form>
		  
		  
		  
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
           <Text color={textColorDetails} fontWeight="400" fontSize="14px">
Already have an account?
<NavLink to="/auth/log-in">
<Text color={colortheme} as="span" ms="5px" fontWeight="500">
Sign in
</Text>
</NavLink>


</Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
