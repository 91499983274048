import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdWifi,
  MdPhone,
  MdCloud,
  MdMail,
  MdTv,
  MdNotifications,
  MdOutlineWhatsapp,
  MdAndroid,
  MdFacebook,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Aitime from "views/admin/airtime";
import Profile from "views/admin/profile";
import Summary from "views/admin/summary";
import Alert from "views/admin/messages";
import Electricity from "views/admin/electric";
import Cable from "views/admin/cable";
import Receipt from "views/admin/receipt";

// Auth Imports
import LogInCentered from "views/auth/auth";
import SignInCentered from "views/auth/signIn";
import SignUp from "views/auth/signUp";
import ForgotPassword from "views/auth/forgotPassword";
import EmailVerification from "views/auth/verify";
import Contact from "views/auth/contact_us";

import Download from "views/auth/download";


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Buy Data",
    layout: "/admin",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdWifi}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Buy Airtime",
    layout: "/admin",
    path: "/airtime",
    icon: (
      <Icon
        as={MdPhone}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Aitime,
    secondary: true,
  },
  {
    name: "Electricity",
    layout: "/admin",
    path: "/electric",
    icon: (
      <Icon
        as={MdPhone}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Electricity,
    secondary: true,
  },
    {
    name: "Cable TV",
    layout: "/admin",
    path: "/cable",
    icon: (
      <Icon
        as={MdTv}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Cable,
    secondary: true,
  },
    {
    name: "Notifications",
    layout: "/admin",
    icon: <Icon as={MdNotifications} width='20px' height='20px' color='inherit' />,
    path: "/notifications",
    component: Alert,
  },
  {
    name: "Summary",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/summary",
    component: Summary,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Contact Us",
    layout: "/auth",
    path: "/contact-us",
    icon: <Icon as={MdMail} width='20px' height='20px' color='inherit' />,
    component: Contact,
  },
  {
    name: "",
    layout: "/auth",
    path: "/sign-in",
     icon: <Icon as={MdLock} width='20px' height='0px' color='white' />,
    component: SignInCentered,
    hidden: true, // Add this line to hide the route
  },
  {
    name: "",
    layout: "/auth",
    path: "/log-in",
     icon: <Icon as={MdLock} width='20px' height='0px' color='white' />,
    component: LogInCentered,
    hidden: true, // Add this line to hide the route
  },
  {
    name: "",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='white' />, // Change color to white
    component: ForgotPassword,
    hidden: true, // Add this line to 
  },
  { 
    name: "",
    layout: "/auth",
    path: "/verify-email",
    icon: <Icon as={MdLock} width='20px' height='0px' color='white' />,
    component: EmailVerification,
    hidden: true, // Add this line to 
  },
  {
    name: "",
    layout: "/auth",
    path: "/sign-up",
     icon: <Icon as={MdLock} width='20px' height='0px' color='white' />,
    component: SignUp,
    hidden: true, // Add this line to 
  },
  {
    name: "",
    layout: "/admin",
    path: "/receipt",
     icon: <Icon as={MdLock} width='20px' height='0px' color='white' />,
    component: Receipt,
    hidden: true, // Add this line to 
  },
];
const visibleRoutes = routes.filter(route => !route.hidden);

export default routes;
