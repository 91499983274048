import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Input,
  Button,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import Modal from 'react-modal';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useReactToPrint } from 'react-to-print';

const styles = StyleSheet.create({
  page: {
 flexDirection: 'row',
 backgroundColor: '#E4E4E4',
 padding: 10,
  },
  section: {
 flexGrow: 1,
  },
});

const authorizationToken = Cookies.get('authorization');

export default function UserReports() {
  const boxBg = useColorModeValue("white", "secondaryGray.300");
  const history = useHistory();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [actualPayment, setActualPayment] = useState(''); 
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [cablePlan, setCablePlan] = useState(''); // Fixed typo in variable name
  const [airtimeAmount, setAirtimeAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [actual_payment, setactualPayment] = useState('');
  const [old_plan, setOldPlan] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [bypassValidation, setBypassValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataPlans, setDataPlans] = useState([]);
  const [responseText, setResponseText] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [previousData, setPreviousData] = useState([]);
  const [lightStatus, setLightStatus] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerName2, setCustomerName2] = useState('');
  const [customerName3, setCustomerName3] = useState('');
  const [customerName4, setCustomerName4] = useState('');
  const [currentBouquet, setCurrentBouquet] = useState('');
  const [renewalAmount, setRenewalAmount] = useState('');
  const jwtToken = Cookies.get('authorization');
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
 if (jwtToken) {
setIsUserLoggedIn(true);
fetchLightStatus();
 } else {
setIsUserLoggedIn(false);
history.push('/auth/sign-in');
 }
  }, [history]);

  const fetchLightStatus = async () => {
 try {
const response = await axios.get(`${BASE_URL}/status`);
const services = response.data.services;
setLightStatus(services.light);
 } catch (error) {
console.error('Error fetching light status:', error);
 }
  };

  const pdfRef = useRef();

  const handlePrintPDF = useReactToPrint({
 content: () => pdfRef.current,
  });

  const handleDownloadPDF = () => {
 const blob = new Blob([responseText], { type: 'application/pdf' });
 const url = URL.createObjectURL(blob);
 const a = document.createElement('a');
 a.href = url;
 a.download = 'response.pdf';
 a.click();
  };




//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({}); // Initialize responseData


useEffect(() => {
siteInfo();
}, []); 

const siteInfo = async () => {
try {
const response = await fetch(`${BASE_URL}/site_info`, {
method: 'GET',
});

if (!response.ok) {
return;
}

const responseData = await response.json();
setResponseData(responseData); // Update the state with responseData

const newPageTitle = responseData.site_name && responseData.site_slogan
? `${responseData.site_name} || ${responseData.site_slogan}`
: responseData.site_name || responseData.ref_perc || 'Default Title';

setPageTitle(newPageTitle);

} catch (error) {
alert('Please Check your internet connection');
}
};

 useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);
const colortheme =responseData.colorTheme;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const handleNetworkChange = async (e) => {
 setSelectedNetwork(e.target.value);
 try {
const pricingResponse = await axios.get(`https://api-service.vtpass.com/api/service-variations?serviceID=${e.target.value}`);
const serviceVariations = pricingResponse.data.content.varations;
setDataPlans(serviceVariations);
 } catch (error) {
console.error('Error fetching pricing:', error);
 }
  };

  const handleAirtimeAmountChange = (e) => {
 setCablePlan(e.target.value); // Fixed the variable name to setCablePlan

 // Calculate actual payment
 const selectedPlan = dataPlans.find((plan) => plan.variation_code === e.target.value);
 if (selectedPlan) {
const variationAmount = parseFloat(selectedPlan.variation_amount);
setActualPayment(variationAmount + 100);
 }
  };









const handlePhoneNumberChange = async (e) => {
  setPhoneNumber(e.target.value);

  if (e.target.value.length === 10) {
    setIsLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}/api/cable_name`, {
        network: selectedNetwork,
        phoneNumber: e.target.value,
      });

      const customerName = response.data.content.Customer_Name;
      const DUE_DATE = response.data.content.Due_Date;

      const old_plan = response.data.content.Current_Bouquet_Code;
      const currentBouquet = response.data.content.Current_Bouquet;
      setCustomerName(customerName);
      setCustomerName2(currentBouquet);
      setCustomerName3(DUE_DATE);
      setCustomerName4(old_plan); // Use 'old_plan' here
    } catch (error) {
      alert('INVALID IUC or Unable to Retrieve Information');
    } finally {
      setIsLoading(false);
    }
  } else {
    setNetworkName('');
    setCustomerName('');
    setCustomerName2('');
    setCustomerName3('');
    setCustomerName4('');
    setCurrentBouquet('');
    setRenewalAmount('');
  }
};

  
  
  
  
  
  
  



const handleBuyNow = async () => {
  if (lightStatus === '0') {
    Swal.fire({
      icon: 'warning',
      text: 'Cable subscription is currently unavailable.',
    });
    return;
  }

  if (!selectedNetwork || !phoneNumber) {
    return;
  }

  setIsLoading(true);

  try {
    if (!jwtToken) {
      throw new Error('Unauthorized');
    }

    const formData = {
      serviceID: selectedNetwork,
      variation_code: cablePlan,
      billersCode: phoneNumber,
      phone: '09038826684',
      quantity: '1',
      old_plan: old_plan,
      actual_payment: actualPayment,
    };

    const response = await axios.post(`${BASE_URL}/api/cable`, formData, {
      headers: {
        Authorization: jwtToken,
      },
    });

    setIsLoading(false);
    Swal.fire({
      icon: 'success',
      text: response.data.message,
    });
	 window.location.href = '#/admin/summary';
  } catch (error) {
    console.error('Error making API request');
    Swal.fire({
      icon: 'error',
      text: error.response ? error.response.data.error : 'An error occurred while making the API request.',
    });
    setIsLoading(false);
  }
};



  
  
  
  
  
  
  
  
  
  
  
  
  
  
  function capitalizeAndFormat(str) {

  const parts = str.split('-');

  // Capitalize each part and join them with a space
  return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
}


  const PDFDocument = () => (
 <Document>
<Page size="A4" style={styles.page}>
  <View style={styles.section}>
 <Text>{responseText}</Text>
  </View>
</Page>
 </Document>
  );

  return (
 <Box pt={{ base: "50px", md: "80px", xl: "80px" }}>
<Box
  bg={boxBg}
  p="20px"
  borderRadius="lg"
  shadow="base"
  width={{ base: "100%", md: "45%", xl: "45%" }}
  mx={{ base: "0", md: "auto" }}
  mb="20px"
>
  <Flex justify="space-between" align="center" mb="20px">
 <Heading as="h3" size="lg">
Cable TV
 </Heading>
  </Flex>
  {lightStatus === '0' ? (
 <div>Light subscription is currently unavailable.</div>
  ) : (
 <form>
<FormControl id="network" mb="4">
  <FormLabel>Provider</FormLabel>
  <Select
 placeholder="Select Provider"
 value={selectedNetwork}
 onChange={handleNetworkChange}
  >
 <option value="gotv">GoTV</option>
 <option value="dstv">DSTV</option>
 <option value="startimes">STARTIME</option>
 <option value="showmax">SHOWMAX</option>
  </Select>
</FormControl>





<FormControl id="cablePlan" mb="4">
  <FormLabel>Cable Plan</FormLabel>
  <Select
    placeholder="Select Cable Plan"
    value={cablePlan}
    onChange={handleAirtimeAmountChange}
  >
    {dataPlans.map((plan) => (
      <option key={plan.variation_code} value={plan.variation_code}>
        {capitalizeAndFormat(plan.variation_code)}
      </option>
    ))}
  </Select>
</FormControl>








<FormControl id="actual_payment" mb="4">
  <FormLabel>Amount</FormLabel>
  <Input
 type="text"
 value={actualPayment}
 readOnly
  />
</FormControl>

<FormControl id="phoneNumber" mb="4">
  <FormLabel>IUC Number</FormLabel>
  <Input
 type="text"
 placeholder="Enter IUC number"
 value={phoneNumber}
 onChange={handlePhoneNumberChange}
  />
  
  {customerName ? (
 <Box my="2" color={customerName.startsWith('Error:') ? "red" : "green"}>
Name: {customerName}<br />
Current Bouquet: {customerName2}<br />
Current Expiry: {customerName3}<br />
<FormControl id="old_plan" mb="4">
  <Input
 type="hidden"
 value={customerName4}
 readOnly
  />
</FormControl>

 </Box>
  ) : null}
</FormControl>

{customerName && currentBouquet && renewalAmount ? (
  <div>
 <Box my="2" color="green">
Customer Name: {customerName}
 </Box>
 <Box my="2" color="green">
Current Bouquet: {currentBouquet}
 </Box>
 <Box my="2" color="green">
Renewal Amount: {renewalAmount}
 </Box>
  </div>
) : null}

<Button
  bg={colortheme}
  color="white"
  mt="4"
  onClick={handleBuyNow}
  isLoading={isLoading}
  loadingText="Awaiting IUC Validation..."
  isDisabled={!customerName}
>
  {customerName ? "IUC Validated. Place Order" : "Validate IUC"}
</Button>
 </form>
  )}
</Box>

<LoadingOverlay
  active={isLoading}
  spinner
  text='Loading...'
  styles={{
 overlay: (base) => ({
...base,
background: 'rgba(0, 0, 0, 0.5)',
zIndex: 9999,
 }),
  }}
/>

<Modal
  isOpen={responseText !== ''}
  onRequestClose={() => setResponseText('')}
  style={{
 overlay: {
backgroundColor: 'rgba(0, 0, 0, 0.75)',
zIndex: 1000,
 },
 content: {
border: 'none',
background: 'transparent',
width: '60%',
maxWidth: '80%',
margin: 'auto',
 },
  }}
>
  <Box bg="white" p="20px" borderRadius="lg" shadow="base">
 <Heading as="h3" size="lg" mb="4">
Electricity Payment
 </Heading>
 <Box
as="pre"
overflow="auto"
maxHeight="500px"
bg="gray.100"
p="4"
borderRadius="md"
 >
Prepaid token has been generated successfully.<br />
Please check the summary page to access the generated token.
 </Box>
 <Flex mt="4" justifyContent="space-between">
<Link to="/summary">
  <Button colorScheme="green">
 Go to Summary
  </Button>
</Link>
<Button colorScheme="red" onClick={() => setResponseText('')}>
  Close
</Button>
 </Flex>
  </Box>
</Modal>

<PDFViewer style={{ display: 'none' }} ref={pdfRef}>
  <PDFDocument />
</PDFViewer>
 </Box>
  );
}
