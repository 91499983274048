import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

function SignIn() {
  useEffect(() => {
    window.location.href = "app.apk"; 
  }, []);

  return <></>;
}

export default SignIn;
