import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { MdNotificationsNone } from 'react-icons/md';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import routes from 'routes.js';

function clearAllCookies() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
  }
}

export default function HeaderLinks(props) {
  const { secondary } = props;
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const ethColor = useColorModeValue('gray.700', 'white');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const jwtToken = Cookies.get('authorization');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');

  const handleLogout = () => {
    fetch(`${BASE_URL}/api/logout`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        clearAllCookies();
        history.push('/auth/sign-in');
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (jwtToken) {
        try {
          const response = await fetch(`${BASE_URL}/api/user`, {
            method: 'GET',
            headers: {
              Authorization: jwtToken,
            },
          });

          if (!response.ok) {
            const errorResponse = await response.json();
            return;
          }

          const data = await response.json();
          setUserData(data.userInformation);
        } catch (error) {}
      }
    };

    fetchUserData();
  }, [jwtToken]);
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({}); // Initialize responseData


useEffect(() => {
siteInfo();
}, []); 

const siteInfo = async () => {
try {
const response = await fetch(`${BASE_URL}/site_info`, {
method: 'GET',
});

if (!response.ok) {
return;
}

const responseData = await response.json();
setResponseData(responseData); // Update the state with responseData

const newPageTitle = responseData.site_name && responseData.site_slogan
? `${responseData.site_name} || ${responseData.site_slogan}`
: responseData.site_name || responseData.ref_perc || 'Default Title';

setPageTitle(newPageTitle);

} catch (error) {
alert('Please Check your internet connection');
}
};

 useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);
const colortheme =responseData.colorTheme;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  return (
    <Flex
      w={{ sm: 'auto', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="5px"
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes} />

      <Text w="max-content" color={ethColor} fontSize="sm" fontWeight="900" me="6px">
        {' '}
       &nbsp; &nbsp; ₦{userData.balance} &nbsp; 
      </Text>
      <Menu>
        {/* Avatar Icon */}
       <MenuButton p="0px">
  <Avatar
    _hover={{ cursor: 'pointer' }}
    color="white"
    name={`${userData.name}`}
    bg={colortheme}
    size="sm"
    w="40px"
    h="40px"
    ml="auto" // Add this line
  />
</MenuButton>

        {/* Avatar Menu */}
        <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="5px" bg={menuBg} border="none">
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={colortheme}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              😊&nbsp; {`${userData.name}`}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
		  
		  
		   <MenuItem
				_hover={{ bg: 'none' }}
				_focus={{ bg: 'none' }}
				borderRadius="8px"
				px="14px"
				
			  >
				<Text fontSize="sm" color={textColor}><a href="#/admin/profile">Profile</a></Text>
			  </MenuItem>
		  
		  
		   <MenuItem
				_hover={{ bg: 'none' }}
				_focus={{ bg: 'none' }}
				borderRadius="8px"
				px="14px"
				
			  >
				<Text fontSize="sm" color={textColor}><a href="#" onClick={handleLogout}>Sign out</a></Text>
			  </MenuItem>
		  
        
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
