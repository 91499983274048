import React, { useState, useEffect } from "react";
import { Global } from '@emotion/react';
import { Grid, GridItem } from "@chakra-ui/react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import IconBox from "components/icons/IconBox";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaClock,
  FaUnlock,
} from "react-icons/fa";

import {
  MdBalance,
  MdBarChart,
  MdVerifiedUser,
  MdContentCopy,
} from "react-icons/md";
import {
Box,
Avatar,
Button,
Checkbox,
Flex,
Image,
Link,
FormControl,
FormLabel,
Heading,
Icon,
Input, 
InputGroup,
InputRightElement,
Text,
useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import Swal from 'sweetalert2';

function SignIn() {
const textColor = useColorModeValue("navy.700", "white");
const textColorSecondary = "gray.400";
const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
const textColorBrand = useColorModeValue("brand.500", "white");
const brandStars = useColorModeValue("brand.500", "brand.400");
const BASE_URL = process.env.REACT_APP_BASE_URL;
const [show, setShow] = React.useState(false);
const [isLoading, setIsLoading] = useState(false);
const [formData, setFormData] = useState({
email: "",
password: "",
});
const [loginMessage, setLoginMessage] = useState("");
const history = useHistory();

const handleInputChange = (event) => {
const { name, value } = event.target;
setFormData((prevData) => ({
...prevData,
[name]: value,
}));
};

const handleClick = () => {
setShow(!show);
};
 const location = useLocation();












const urlSegments = window.location.href.split("/");
const hasInviteCode = urlSegments[3] === "inv" && urlSegments[4];

useEffect(() => {
  if (hasInviteCode) {
    const inviteCode = urlSegments[4];
    history.push(`/auth/sign-up?invite=${inviteCode}`);
  }
}, [hasInviteCode, urlSegments, history]);












const [pageTitle, setPageTitle] = useState("Buy Data, Airtime Recharge, Bill payment");
const [responseData, setResponseData] = useState({});

useEffect(() => {
siteInfo();
}, []); 

const siteInfo = async () => {
try {
const response = await fetch(`${BASE_URL}/site_info`, {
method: 'GET',
});

if (!response.ok) {
return;
}

const responseData = await response.json();
setResponseData(responseData);

const newPageTitle = responseData.site_name && responseData.site_slogan
? `${responseData.site_name} || ${responseData.site_slogan}`
: responseData.site_name || responseData.ref_perc || 'Default Title';

setPageTitle(newPageTitle);
} catch (error) {
alert('Please check your Internet connection');
}
};

useEffect(() => {
document.title = pageTitle;
}, [pageTitle]);


const colortheme =responseData.colorTheme;


///use homepage 1
if (responseData.homepage==1) {
// lets get the url so we can redirect

const redirect= `https://${responseData.site_url}/index.php`;

//alert(redirect);

window.location.href = `${redirect}`;

return null;
}



function setAuthorizationCookie(authorization) {
const cookieName = 'authorization';

const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + 7); // Cookie will expire in 7 days

// Set the cookie with the desired options (httpOnly, secure, etc.)
document.cookie = `${cookieName}=${authorization}; expires=${expirationDate.toUTCString()}; path=/; secure; SameSite=Strict`;
}

return (
<DefaultAuth>
	




<React.Fragment>
<Global
styles={{
body: {
margin: 0,
padding: 0,
overflowX: 'hidden',
},
html: {
margin: 0,
padding: 0,
},
}}
/>
	
<Flex
  direction="column"
  alignItems="center"
  justifyContent="center"
  h="100vh"
  backgroundImage={`url(${responseData.hero_image})`}
  backgroundSize="cover"
  backgroundPosition="center"
  color="white"
  width="100%"
  textAlign="center"
  textShadow="2px 2px 2px rgba(0, 0, 0, 0.5)"
>

  {/* Logo */}
  <Image src={responseData.site_logo} alt="Logo" height="60px" mb="3" />

  <Heading fontSize="4xl" mb="4">
    Welcome to {responseData.site_name} 
  </Heading>

  <Text fontSize="md" mb="5" p="4">
    We provide immediate top-up services for airtime, data bundles, cable TV subscriptions (DSTV, GOTV, & STARTIMES), electricity bill payments, and the conversion of airtime to cash.
  </Text>

  <Flex>
    <NavLink to="/auth/log-in">
      <Button colorScheme="green" size="lg" onClick={handleClick}>
       <Icon as={MdVerifiedUser} boxSize={4} />  Sign In
      </Button>
    </NavLink>
    <NavLink to="/auth/sign-up">
<Button backgroundColor={colortheme} size="lg" onClick={handleClick} ml={4}>
       <Icon as={FaUnlock} boxSize={4} /> Sign Up
      </Button>
    </NavLink>
  </Flex>

</Flex>





</React.Fragment>













   
<Grid templateColumns={{ base: "1", md: "repeat(2, 1fr)" }} gap='5px'>


<GridItem>
<Box w="100%" p="4"  color="black">
<Heading fontSize="lg" mb="2" display="inline-block" borderBottom={`1px solid ${colortheme}`}>

About Us
</Heading>
<Text>
We are a dynamic service provider dedicated to delivering instant solutions for your communication and utility needs. Whether it's airtime, data, cable TV subscriptions, electricity bills, or turning airtime into cash, we've got you covered with speed and efficiency.
</Text>

<br /><br />
<Heading fontSize="lg" mb="2">
Features that make us unique
</Heading>

</Box>
</GridItem>




<GridItem>
<Box w="100%" p="4" bg={`${colortheme}.100`} color="black">
<Text>
<b>Trustworthy and Dependable</b><br />
As a meticulously optimized platform, we prioritize reliability and dependability. Experience unparalleled value, with a guarantee of 100% satisfaction in every transaction.

</Text>
</Box>
</GridItem>






<GridItem>
<Box w="100%" p="4" bg={`${colortheme}.100`} color="black">
<Text>
<b>Embracing Automation</b><br />

Our data delivery and wallet funding processes are streamlined through automation. Experience near-instantaneous airtime top-ups and data purchases, seamlessly delivered to you.
</Text>
</Box>
</GridItem>




<GridItem>
<Box w="100%" p="4" bg={`${colortheme}.100`} color="black">
<Text>
<b>Accessible Customer Assistance</b><br />
Our customer support is just a click away. Feel free to reach out for any queries or concerns. Rest assured, all transactions are addressed promptly, typically within 5-15 minutes.

</Text>
</Box>
</GridItem>
</Grid>


















<Grid templateColumns={{ base: "1", md: "1" }} gap='5px'>

<GridItem>
<Box w="100%" p="6" color="black">
<Heading fontSize="lg" mb="2" display="inline-block" borderBottom={`1px solid ${colortheme}`}>Our Pricing<hr />
</Heading>
<Text>
Pricing coming soon..
</Text>
</Box>
</GridItem>
</Grid>

























<Grid templateColumns={{ base: "1", md: "repeat(2, 1fr)" }} gap='5px'>

<GridItem>
<Box w="100%" p="4" color="black">
<Heading fontSize="lg" mb="2" display="inline-block" borderBottom={`1px solid ${colortheme}`}>Become a Reseller<hr />
</Heading>
<Text>
Join our entrepreneur network with network.com! Bring the 'easy-payments' experience to your network, earn commissions for transactions, and enjoy the best referral incentives. Foster entrepreneurial skills, encourage growth among students and youth, and promote technology in our society through ICT tools.
</Text>
</Box>
</GridItem>


<GridItem>
<Box w="100%" p="4" color="black">
<Heading fontSize="lg" mb="2" display="inline-block" borderBottom={`1px solid ${colortheme}`}>Get in Touch with Our Team<hr />
</Heading>
<Text>
For any issues or questions, please reach out to us.<br /><br />

<Icon as={FaPhoneAlt} boxSize={4} /> Call & Text us on: {responseData.contact_number}<br /><br />
<Icon as={FaEnvelope} boxSize={4} /> Mail us at: {responseData.web_contact_email}<br /><br />
<Icon as={FaMapMarkerAlt} boxSize={4} /> Address: {responseData.address}<br /><br />

<Icon as={FaClock} boxSize={4} /> Working Time: 24/7<br />

</Text>
</Box>
</GridItem>
</Grid>

<br />
<hr />

</DefaultAuth>
);
}

export default SignIn;
