import {
  Flex,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from 'axios';

import Card from "components/card/Card.js";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function Conversion(props) {
  const { ...rest } = props;

  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const jwtToken = Cookies.get('authorization');

  // Fetch contacts on component mount
  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/contact`, {
        headers: {
          Authorization: jwtToken,
        },
      });
      const data = await response.json();
      setContacts(data.contacts);
      setFilteredContacts(data.contacts);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = contacts.filter(
      (contact) =>
        (contact.name && contact.name.toLowerCase().includes(query)) ||
        (contact.phoneNumber && contact.phoneNumber.includes(query))
    );
    setFilteredContacts(filtered);
  };






const handleDelete = async (number_id) => {
  try {
  
    const response = await axios.get(`${BASE_URL}/api/del_contt?number_id=${number_id}`);

    if (response.status === 200) {
      Swal.fire({
        text: `Contact Deleted`,
        showConfirmButton: false,
        timer: 1100,
      });
      window.location.reload();

    } else {
     
      console.error('Unexpected response status:', response.status);
      
    }
  } catch (error) {
    console.error('Error during deletion request:', error);
   
  }
};











  const handleCopyPhoneNumber = (phoneNumber, name) => {
    navigator.clipboard.writeText(phoneNumber);

    // Use SweetAlert to display the alert
    Swal.fire({
      text: `${name} phone number copied.`,
      showConfirmButton: false,
      timer: 1500 // Display for 2 seconds
    });
  };

  return (
    <Card p='20px' align='center' height='450px' direction='column' w='100%' {...rest} style={{ maxHeight: '300px', overflowY: 'scroll' }}>
      <Flex
        px={{ base: "1px", "1xl": "20px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='20px'
      >
        <Input
          placeholder='Search Saved contacts'
          onChange={handleSearchChange}
          value={searchQuery}
          w='90%'
        />
      </Flex>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>Contact</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredContacts.map((contact) => (
            <Tr key={contact.id}>
              <Td >
                <Flex alignItems='center'>
                  <Text fontWeight='bold'>{contact.name} [{contact.netork}]</Text>
                 
                </Flex>
                <Text>{contact.dnumber}</Text> <Button
                  size='sm'
                  colorScheme='red'
                  onClick={() => handleDelete(contact.id)}
                >
                  Delete
                </Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <Button
                  size='md'
                  colorScheme='blue'
                  onClick={() => handleCopyPhoneNumber(contact.dnumber, contact.name)}
                >
                  Copy
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Card>
  );
}
