import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import Swal from 'sweetalert2';

function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const googleHover = useColorModeValue(
 { bg: "gray.200" },
 { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
 { bg: "secondaryGray.300" },
 { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
 email: "",
 password: "",
  });
  const [loginMessage, setLoginMessage] = useState(""); // State to store the login message
  const history = useHistory();

  const handleInputChange = (event) => {
 const { name, value } = event.target;
 setFormData((prevData) => ({
...prevData,
[name]: value,
 }));
  };

  
  const handleSignIn = async (event) => {
 event.preventDefault();
 setIsLoading(true);

 try {
const sanitizedEmail = formData.email.trim();
  


const response = await axios.post(`${BASE_URL}/api/reset_password`, {
  email: sanitizedEmail,});

// Check if login was successful
if (response.data.status) {
Swal.fire({
  icon: 'success',
  text: 'An email containing password reset instruction has been sent to your email',
  confirmButtonColor: '#d33',
  confirmButtonText: 'OK',
});
}
 else {
  // Show error SweetAlert
  Swal.fire({
 icon: 'error',
 text: `${response.data.message}`,
 confirmButtonColor: '#d33',
 confirmButtonText: 'OK',
  });
}
 } catch (error) {
console.error("Error during login:", error.message);
// Show error SweetAlert for connection problem
Swal.fire({
  icon: 'error',
  title: 'Error',
  text: 'An error occurred. Please try again later.',
  confirmButtonColor: '#d33',
  confirmButtonText: 'OK',
});
 }

 setIsLoading(false);
  };
// Function to set the authorization token as a cookie
function setAuthorizationCookie(authorization) {
  // Define the cookie name
  const cookieName = 'authorization';
  
  // Set the expiration date (optional, adjust as needed)
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7);
  document.cookie = `${cookieName}=${authorization}; expires=${expirationDate.toUTCString()}; path=/; secure; SameSite=Strict`;
}
  return (
 <DefaultAuth illustrationBackground={illustration} image={illustration}>
<Flex
  maxW={{ base: "100%", md: "max-content" }}
  w="100%"
  mx={{ base: "auto", lg: "0px" }}
  me="auto"
  h="100%"
  alignItems="start"
  justifyContent="center"
  mb={{ base: "30px", md: "60px" }}
  px={{ base: "25px", md: "0px" }}
  mt={{ base: "40px", md: "14vh" }}
  flexDirection="column"
>
  <Box me="auto">
 <Heading color={textColor} fontSize="36px" mb="10px">
Reset password
 </Heading>
 <Text
mb="36px"
ms="4px"
color={textColorSecondary}
fontWeight="400"
fontSize="md"
 >
Enter your registered email address
 </Text>
  </Box>
  <Flex
 zIndex="2"
 direction="column"
 w={{ base: "100%", md: "420px" }}
 maxW="100%"
 background="transparent"
 borderRadius="15px"
 mx={{ base: "auto", lg: "unset" }}
 me="auto"
 mb={{ base: "20px", md: "auto" }}
  >
 <FormControl>
<FormLabel
  display="flex"
  ms="4px"
  fontSize="sm"
  fontWeight="500"
  color={textColor}
  mb="8px"
>
  Email<Text color={brandStars}>*</Text>
</FormLabel>
<Input
  isRequired={true}
  variant="auth"
  fontSize="sm"
  ms={{ base: "0px", md: "0px" }}
  type="email"
  placeholder="user@email.com"
  mb="24px"
  fontWeight="500"
  size="lg"
  name="email"
  value={formData.email}
  onChange={handleInputChange}
/>
  
	
<Flex justifyContent="space-between" align="center" mb="24px">
  <FormControl display="flex" alignItems="center">

  </FormControl>

</Flex>
<Button
  isLoading={isLoading}
  fontSize="sm"
  variant="brand"
  fontWeight="500"
  w="100%"
  h="50"
  mb="24px"
  onClick={handleSignIn}
>
  Reset Password
</Button>
 </FormControl>
 <Flex
flexDirection="column"
justifyContent="center"
alignItems="start"
maxW="100%"
mt="0px"
 >
{/* Display the login message */}
{loginMessage && (
  <Text color={loginMessage.includes("successful") ? "green.500" : "red.500"} fontWeight="500" fontSize="14px">
 {loginMessage}
  </Text>
)}

<Text color={textColorDetails} fontWeight="400" fontSize="14px">
  Not registered yet?
  <NavLink to="/auth/sign-in">
 <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
Sign In
 </Text>
  </NavLink>
</Text>
 </Flex>
  </Flex>
</Flex>
 </DefaultAuth>
  );
}

export default SignIn;
