import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useAuthentication = () => {
    const history = useHistory();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  
    useEffect(() => {
      const authorization = getAuthorizationHeaderFromCookie();
      if (authorization) {
        setIsUserLoggedIn(true);
      } else {
        setIsUserLoggedIn(false);
        history.push('/auth/sign-in');
      }
    }, [history]);
  
    const getAuthorizationHeaderFromCookie = () => {
      const authorization = document.cookie
        .split(';')
        .map(cookie => cookie.trim())
        .find(cookie => cookie.startsWith('authorization='));
  
      if (authorization) {
        return authorization.split('=')[1];
      }
      return null;
    };
  
    return isUserLoggedIn;
  };
  
  export default useAuthentication;
  